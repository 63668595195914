import { Modal } from '@77sol-ui/molecules'
import { Content } from './components/Content'
import { LoadingAnimated } from './components/LoadingAnimated'
import { type IQuotationModalProps } from './types'
import { useQuotationModal } from './hooks/useQuotationModal'
import { useCancelQuotationContainer } from './hooks/useCancelQuotationModal'
import { useCallback } from 'react'

export function QuotationModal({ open, onOpenChange }: IQuotationModalProps) {
  const { isLoading, isError, requestFallback, handleCreateQuotation } =
    useQuotationModal({ onOpenChange })

  const { handleCancelQuotationRequest } = useCancelQuotationContainer()

  const handleOpenChange = useCallback(
    (value: boolean) => {
      if (!value) {
        handleCancelQuotationRequest()
      }

      onOpenChange(value)
    },
    [handleCancelQuotationRequest, onOpenChange],
  )

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Portal>
        <Modal.Overlay zIndex={1000}>
          {!isLoading && (
            <Content
              onCreateQuotation={handleCreateQuotation}
              defaultValues={requestFallback}
            />
          )}
        </Modal.Overlay>
        {isLoading && !isError && <LoadingAnimated />}
      </Modal.Portal>
    </Modal.Root>
  )
}
