import { Button } from '@77sol-ui/atoms'
import { Grid3X3 } from 'lucide-react'
import { QuotationForm } from '../QuotationForm'
import { type IModalWrapperProps } from '@77sol-ui/molecules'
import { type IQuotationFormProps } from '../QuotationForm/types'
import { type CSSProperties } from 'react'
import { Modal } from 'components/Modal'

interface IContentProps extends IModalWrapperProps {
  onCreateQuotation: IQuotationFormProps['onSubmit']
  onCancel?: () => void
  defaultValues?: IQuotationFormProps['defaultValues']
  style?: CSSProperties
}

export function Content({
  onCreateQuotation,
  onCancel = () => {},
  defaultValues,
  ...props
}: IContentProps) {
  return (
    <Modal.Wrapper maxWidth="640px" maxHeight="90vh" {...props}>
      <Modal.Close onClick={onCancel} />
      <Modal.Header>
        <Modal.Icon icon={Grid3X3} />
        <Modal.Title>Cotação de Equipamentos</Modal.Title>
        <Modal.Description>
          Preencha os campos abaixo para cotar os equipamentos
        </Modal.Description>
      </Modal.Header>

      <Modal.Content>
        <QuotationForm
          formId="modal-quotation-form"
          onSubmit={onCreateQuotation}
          defaultValues={defaultValues}
        />
      </Modal.Content>

      <Modal.Footer>
        <Modal.Close asChild onClick={onCancel}>
          <Button fullWidth variant="outline">
            Cancelar
          </Button>
        </Modal.Close>

        <Button fullWidth type="submit" form="modal-quotation-form">
          Cotar equipamentos
        </Button>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
