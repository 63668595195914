import { Checkbox } from '@77sol-ui/atoms'
import { Table } from '@77sol-ui/molecules'
import {
  type IQuotationDTO,
  type IDimensioningGETResponse,
} from 'domains/dimensioning/services/types'

import { parseBRL } from 'utils'
import { useState } from 'react'
import { Content } from '../../styles'
import useWindowSize from 'hooks/useWindowSize'

import * as S from './styles'
import { CircularProgress } from 'components'
import { ErrorState } from 'components/ErrorState'
import { usePdfStore } from '../../hooks/usePDFStore'

export interface IChooseQuotationProps {
  onChangeQuotations: (quotations: IQuotationDTO[]) => void
  quotations: IDimensioningGETResponse['quotations']
  initialSelectedQuotations: IQuotationDTO[]
  dimensioningDataIsLoading?: boolean
  dimensioningDataIsError?: boolean
}

export const ChooseQuotation: React.FC<IChooseQuotationProps> = ({
  quotations,
  onChangeQuotations,
  initialSelectedQuotations,
  dimensioningDataIsLoading,
  dimensioningDataIsError,
}) => {
  const [selectedQuotations, setSelectedQuotations] = useState(
    new Map(
      initialSelectedQuotations.map((quotation) => [quotation.id, quotation]),
    ),
  )

  const { setAllPDFsLoaded, setFirstPdfLoaded } = usePdfStore()

  const handleSelectQuotation = (quotation: IQuotationDTO) => {
    setAllPDFsLoaded(false)
    setFirstPdfLoaded(false)
    const updatedSelectedQuotations = selectedQuotations
    const findSelectedQuotations = updatedSelectedQuotations.get(quotation.id)

    if (findSelectedQuotations) updatedSelectedQuotations.delete(quotation.id)
    else updatedSelectedQuotations.set(quotation.id, quotation)

    setSelectedQuotations(updatedSelectedQuotations)
    onChangeQuotations(Array.from(updatedSelectedQuotations.values()))
  }

  const { width } = useWindowSize()

  const isMobile = width <= 991

  return (
    <Content>
      <S.Wrapper>
        <S.StyledTableRoot variant="default">
          <Table.Header headerTemplateColumns={'0.2fr 0.5fr 0.7fr 1fr 1fr 1fr'}>
            <S.StyledTableHead></S.StyledTableHead>
            <S.StyledTableHead>ID</S.StyledTableHead>
            <S.StyledTableHead>Potência</S.StyledTableHead>
            <S.StyledTableHead>Equipamentos</S.StyledTableHead>
            <S.StyledTableHead>Custos Extras</S.StyledTableHead>
            <S.StyledTableHead>Valor Total</S.StyledTableHead>
          </Table.Header>
          <Table.Body maxHeight={isMobile ? '35vh' : 250}>
            {dimensioningDataIsLoading && (
              <div className="loadingContainer">
                <CircularProgress text="Carregando..." />
              </div>
            )}

            {!dimensioningDataIsLoading &&
              !dimensioningDataIsError &&
              quotations.length &&
              quotations?.map((item) => (
                <Table.Row
                  key={item.id}
                  data-testid={`row-${item.id}`}
                  rowType="table"
                  onClick={() => {
                    handleSelectQuotation(item)
                  }}
                  rowTemplateColumns={'0.2fr 0.5fr 0.7fr 1fr 1fr 1fr'}
                >
                  <S.StyledTableCell>
                    <Checkbox
                      id={String(item.id)}
                      data-testid={`check-${item.id}`}
                      checked={Boolean(selectedQuotations.get(item.id))}
                    />
                  </S.StyledTableCell>
                  <S.StyledTableCell mobileHead="ID">
                    <span>{item.id}</span>
                  </S.StyledTableCell>
                  <S.StyledTableCell mobileHead="Potência">
                    <span>{item.potency}kWp</span>
                  </S.StyledTableCell>
                  <S.StyledTableCell mobileHead="Equipamentos">
                    <span>{parseBRL(item.equipment_value)}</span>
                  </S.StyledTableCell>
                  <S.StyledTableCell mobileHead="Custos Extras">
                    <span>{parseBRL(item.extra_costs)}</span>
                  </S.StyledTableCell>
                  <S.StyledTableCell mobileHead="Valor Total">
                    <span>{parseBRL(item.total_value)}</span>
                  </S.StyledTableCell>
                </Table.Row>
              ))}

            {!dimensioningDataIsLoading && dimensioningDataIsError && (
              <ErrorState
                title="Ops! Algo deu errado."
                subtitle="Não foi possível carregar as cotações."
                showImage
              />
            )}
          </Table.Body>
        </S.StyledTableRoot>
      </S.Wrapper>
    </Content>
  )
}
